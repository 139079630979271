// src/store/dashboardSlice.js
import { createSlice } from '@reduxjs/toolkit';

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    showAllDashboardItems: false,
  },
  reducers: {
    showAllItems(state) {
      state.showAllDashboardItems = true;
    },
    showOnlyMyChildren(state) {
      state.showAllDashboardItems = false;
    }
  },
});

export const { showAllItems, showOnlyMyChildren} = dashboardSlice.actions;
export default dashboardSlice.reducer;
