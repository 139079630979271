import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logoImg from '../../assets/images/mainlogo.png';
import { useDispatch, useSelector } from 'react-redux';
import { showAllItems, showOnlyMyChildren } from '../../app/dashboardSlice';

function Aside() {

    const dispatch = useDispatch();
    const showAllDashboardItems = useSelector((state) => state.dashboard.showAllDashboardItems);
console.log('dashh' , showAllDashboardItems);
    const [allAside, setAllAside] = useState({
        admin: false,
        dashboard: false,
        inventory: false,
        career: false,
        setting: false,
        Reception: false,
        academic: false,
        examMaster: false,
        supervision: false,
        library: false,

    });

    const changeOpen = (val, str) => {
        const clone = { ...allAside };
        for (const iterator in clone) {
            if (iterator == str) {
                clone[str] = val;
            } else {
                clone[iterator] = false;
            }
        }
        setAllAside(clone);
    };

    return (
        <>
            <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme" data-bg-className="bg-menu-theme" >
                <div className="app-brand demo d-flex justify-content-between align-items-center">
                    <Link to="/admin" className="app-brand-link">
                        <img width={150} src={logoImg} alt="img" />
                    </Link>
                    <a href="" className="layout-menu-toggle menu-link text-large">
                        <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto">
                            <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle" />
                            <i className="ti ti-x d-block d-xl-none ti-sm align-middle" />
                        </a>
                    </a>
                </div>
                <div className="menu-inner-shadow" style={{ display: 'none' }} />
                <ul className="menu-inner py-1 ps ps--active-y " id="scroll">
                    {/* Charts & Maps */}
                    <li className="menu-header small text-uppercase">
                        <span className="menu-header-text" data-i18n="Main">Main</span>
                    </li>


                    <li className={`menu-item ${allAside.dashboard ? "open" : ""}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => {
                            changeOpen(!allAside.dashboard, "dashboard");
                        }}>
                            <i className="menu-icon tf-icons ti ti-layout-grid" />
                            <div data-i18n="Dashboard">Dashboard</div>
                        </Link >
                        <ul className="menu-sub">
                            <li
                                onClick={() => {
                                    dispatch(showOnlyMyChildren());
                                }}
                                className="menu-item">
                                <Link to="" className="menu-link">
                                    <div data-i18n="My Children">My Children</div>
                                </Link>
                            </li>
                            {showAllDashboardItems && (
                                <li
                                    onClick={() => {
                                        dispatch(showAllItems());
                                    }}
                                    className="menu-item">
                                    <Link to="dashboard" className="menu-link">
                                        <div data-i18n="Dashboard">Dashboard</div>
                                    </Link>
                                </li>
                            )}

                        </ul>
                    </li>
                    {showAllDashboardItems && (
                        <>
                            <li className="menu-item">
                                <Link to="teachers" className="menu-link">
                                    <i className="menu-icon tf-icons ti ti-users" />
                                    <div data-i18n="Teachers">Teachers</div>
                                </Link>
                            </li>



                            <li className={`menu-item ${allAside.academic ? "open" : ""}`}>
                                <Link to="#" className="menu-link menu-toggle" onClick={() => {
                                    changeOpen(!allAside.academic, "academic");
                                }}>
                                    <i className="menu-icon tf-icons ti ti-smart-home" />
                                    <div data-i18n="Academic">Academic</div>
                                    {/* <div className="badge bg-primary rounded-pill ms-auto">5</div> */}
                                </Link >
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <Link to="academic_subject" className="menu-link">
                                            <div data-i18n="Subject">Subject</div>
                                        </Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="academic_class_schedule" className="menu-link">
                                            <div data-i18n="Class Schedule">Class Schedule</div>
                                        </Link>
                                    </li>

                                </ul>
                            </li>

                            <li className="menu-item">
                                <Link to="leave_application" className="menu-link">
                                    <i className="menu-icon tf-icons ti ti-files" />
                                    <div data-i18n="Leave Application">Leave Application</div>
                                </Link>
                            </li>

                            <li className="menu-item">
                                <Link to="attachments_book" className="menu-link">
                                    <i className="menu-icon tf-icons ti ti-cloud-upload" />
                                    <div data-i18n="Attachments Book">Attachments Book</div>
                                </Link>
                            </li>

                            <li className="menu-item">
                                <Link to="homework" className="menu-link">
                                    <i className="menu-icon tf-icons ti ti-edit" />
                                    <div data-i18n="HomeWork">HomeWork</div>
                                </Link>
                            </li>

                            <li className={`menu-item ${allAside.examMaster ? "open" : ""}`}>
                                <Link to="#" className="menu-link menu-toggle" onClick={() => {
                                    changeOpen(!allAside.examMaster, "examMaster");
                                }}>
                                    <i className="menu-icon tf-icons ti ti-vocabulary" />
                                    <div data-i18n="Exam Master">Exam Master</div>
                                    {/* <div className="badge bg-primary rounded-pill ms-auto">5</div> */}
                                </Link >
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <Link to="exam_schedule" className="menu-link">
                                            <div data-i18n="Exam Schedule">Exam Schedule</div>
                                        </Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="report_card" className="menu-link">
                                            <div data-i18n="Report Card">Report Card</div>
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className={`menu-item ${allAside.supervision ? "open" : ""}`}>
                                <Link to="#" className="menu-link menu-toggle" onClick={() => {
                                    changeOpen(!allAside.supervision, "supervision");
                                }}>
                                    <i className="menu-icon tf-icons ti ti-vocabulary" />
                                    <div data-i18n="Supervision">Supervision</div>
                                    {/* <div className="badge bg-primary rounded-pill ms-auto">5</div> */}
                                </Link >
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <Link to="supervision_hotel" className="menu-link">
                                            <div data-i18n="Foster Rooms">Foster Rooms</div>
                                        </Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="supervision_transport" className="menu-link">
                                            <div data-i18n="Transport">Transport</div>
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="menu-item">
                                <Link to="attendance" className="menu-link">
                                    <i className="menu-icon tf-icons ti ti-chart-bar" />
                                    <div data-i18n="Attendance">Attendance</div>
                                </Link>
                            </li>


                            <li className={`menu-item ${allAside.library ? "open" : ""}`}>
                                <Link to="#" className="menu-link menu-toggle" onClick={() => {
                                    changeOpen(!allAside.library, "library");
                                }}>
                                    <i className="menu-icon tf-icons ti ti-vocabulary" />
                                    <div data-i18n="Library">Library</div>
                                    {/* <div className="badge bg-primary rounded-pill ms-auto">5</div> */}
                                </Link >
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <Link to="books_list" className="menu-link">
                                            <div data-i18n="Books List">Books List</div>
                                        </Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="books_issued" className="menu-link">
                                            <div data-i18n="Issued Books">Issued Books</div>
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="menu-item">
                                <Link to="event" className="menu-link">
                                    <i className="menu-icon tf-icons ti ti-message" />
                                    <div data-i18n="Events">Events</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="fees_history" className="menu-link">
                                    <i className="menu-icon tf-icons ti ti-calculator" />
                                    <div data-i18n="Fees History">Fees History</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="message" className="menu-link">
                                    <i className="menu-icon tf-icons ti ti-mail-opened" />
                                    <div data-i18n="Message">Message</div>
                                </Link>
                            </li>




                            <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                                <div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }} />
                            </div>
                            <div className="ps__rail-y" style={{ top: 0, height: 384, right: 4 }}>
                                <div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 74 }} />
                            </div>

                        </>
                    )}

                </ul>

            </aside>

        </>
    )
}
export default Aside