import '../../styles/profileStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';
import { useRef, useState } from "react";
import LeaveApplicationData from '../../data/LeaveApplicationData';

import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'

const mangeTabActive = {
    color: '#7367f0',
    borderBottom: '2px solid #7367f0',
    // backgroundColor: '#7367f0',
    borderRadius: '0',
}

const LeaveApplication = () => {

    const [value, setValue] = useState(0);

    const onHandleChange = (event, newValue) => {
        setValue(newValue)
    }


    const fileInputRef = useRef(null);

    const handleFormClick = () => {
        fileInputRef.current.click();
    };

    const defalutValue = {
        leaveType: '',
        date: '',
        reason: '',
        message: '',
        attachmentFile: ''
    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        leaveType: yup.string().required('Leave Type is Requird!'),
        date: yup.date().required('Date is Requird!'),
        reason: yup.string().required('Reason is Requird!'),
        message: yup.string().required('Message is Requird!'),
        attachmentFile: yup.string().required('Attachment File is Requird!'),
    })
    const handleSubmit = (value) => {
        console.log(value);
    }


    return (
        <>

            <div className="tabsBox">
                <Tabs value={value} onChange={onHandleChange} className="tabs">
                    <div className="left-tabs">
                        <TabsList className='mb-3'>
                            <Tab style={value === 0 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={0}><i className='manageicon ti ti-list' /> Leave List</Tab>
                            {/* <Tab style={value === 1 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={1}><i class="manageicon fa-solid fa-edit" /> Leave Request</Tab> */}
                        </TabsList>
                    </div>
                    <div className="TabPanelDiv">
                        <TabPanel value={0}>
                            <div className="card p-3">
                                <div className="card-datatable table-responsive">
                                    <div id="DataTables_Table_3_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="DataTables_Table_3_length">
                                                    <label>
                                                        <select name="DataTables_Table_3_length" aria-controls="DataTables_Table_3" className="form-select">
                                                            <option value={7}>7</option>
                                                            <option value={10}>10</option>
                                                            <option value={25}>25</option>

                                                            <option value={50}>50</option>
                                                            <option value={75}>75</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                        rows per page
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                                <div id="DataTables_Table_3_filter" className="dataTables_filter">
                                                    <label>
                                                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_3" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive text-nowrap">
                                            <Table component={'table'} className="dt-multilingual table table-bordered dataTable no-footer dtr-column" id="DataTables_Table_3" aria-describedby="DataTables_Table_3_info">
                                                <TableHead component={'thead'} className=' table-light'>
                                                    <TableRow component={'tr'}>
                                                        <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label='Sl: activate to sort column ascending'>Sl</TableCell>
                                                        <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Applicant: activate to sort column ascending">Applicant</TableCell>
                                                        <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Leave Category: activate to sort column ascending">Leave Category</TableCell>
                                                        <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Date Of Start: activate to sort column ascending">Date Of Start</TableCell>
                                                        <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Date Of End: activate to sort column ascending">Date Of End</TableCell>
                                                        <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Days: activate to sort column ascending">Days</TableCell>
                                                        <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Apply Date: activate to sort column ascending">Apply Date</TableCell>
                                                        <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Status: activate to sort column ascending">Status</TableCell>
                                                        <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Action: activate to sort column ascending">Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody component={'tbody'}>
                                                    {LeaveApplicationData.map((item) => (
                                                        <>
                                                            <TableRow component={'tr'} className="odd">
                                                                <TableCell component={'td'} valign="top" className="dataTables_empty">{item.Title}</TableCell>
                                                                <TableCell component={'td'} valign="top" className="dataTables_empty">{item.Title}</TableCell>
                                                                <TableCell component={'td'} valign="top" className="dataTables_empty">{item.Title}</TableCell>
                                                                <TableCell component={'td'} valign="top" className="dataTables_empty">{item.Title}</TableCell>
                                                                <TableCell component={'td'} valign="top" className="dataTables_empty">{item.Title}</TableCell>
                                                                <TableCell component={'td'} valign="top" className="dataTables_empty">{item.Title}</TableCell>
                                                                <TableCell component={'td'} valign="top" className="dataTables_empty">{item.Title}</TableCell>
                                                                <TableCell component={'td'} valign="top" className="dataTables_empty"><span class="badge bg-label-warning me-1">Pending</span></TableCell>
                                                                <TableCell component={'td'} valign="top" className="dataTables_empty">
                                                                    <div className=' d-flex align-items-center'>
                                                                        <button type="button" class="btn rounded-pill btn-icon btn-outline-dark waves-effect me-1 btn-sm">
                                                                            <span class="ti ti-menu-2 ti-xs"></span>
                                                                        </button>
                                                                        <button type="button" class="btn rounded-pill btn-icon btn-danger waves-effect waves-light btn-sm">
                                                                            <span class="ti ti-trash ti-xs"></span>
                                                                        </button>
                                                                    </div>

                                                                </TableCell>

                                                            </TableRow>
                                                        </>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_info" id="DataTables_Table_3_info" role="status" aria-live="polite"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_3_paginate">
                                                    <ul className="pagination">
                                                        <li className="paginate_button page-item previous disabled" id="DataTables_Table_3_previous"><a aria-controls="DataTables_Table_3" aria-disabled="true" role="link" data-dt-idx="previous" tabIndex={-1} className="page-link">Previous</a></li>
                                                        <li className="paginate_button page-item next disabled" id="DataTables_Table_3_next"><a aria-controls="DataTables_Table_3" aria-disabled="true" role="link" data-dt-idx="next" tabIndex={-1} className="page-link">Next</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>

                        {/* <TabPanel value={1}>
                            <div className="card p-3">
                                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                    {({ errors, touched, resetForm }) => {
                                        return <Form className="row">
                                            <div className="card-body">
                                                <div id="formValidationExamples" className="row g-3 w-50 m-auto">

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationSelect2">Leave Type</label>
                                                        <Field component='select' name='leaveType' className={
                                                            `form-control
                                                            ${errors.leaveType && touched.leaveType ? ' is-invalid' : ''}`
                                                        }>
                                                            <option >--Select--</option>
                                                            <option value={71}>Airtel Prepaid</option>
                                                            <option value={82}>BSNL Prepaid</option>
                                                            <option value={83}>Vi Prepaid</option>
                                                            <option value={94}>JIO</option>
                                                        </Field>
                                                        <p className='error_message_p'> <ErrorMessage name="leaveType" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationName">Date</label>
                                                        <Field type='datetime-local'
                                                            name='date' placeholder='Enter Product Name '
                                                            className={
                                                                `form-control
                                                                     ${errors.date && touched.date ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="date" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationBio">Reason</label>
                                                        <Field
                                                            as="textarea"
                                                            className={
                                                                `form-control
                                                                     ${errors.reason && touched.reason ? ' is-invalid' : ''}`
                                                            }
                                                            name="reason"
                                                            rows={3} defaultValue={""}
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="reason" /></p>
                                                    </div>



                                                    <div className="col-12">
                                                        <div className="card mb-4">
                                                            <h5 className="card-header">Attachment</h5>
                                                            <div className="card-body p-0">
                                                                <form onClick={handleFormClick} action="" className="dropzone needsclick dz-clickable" id="dropzone-basic">
                                                                    <div className="dz-message needsclick fs-6">
                                                                        Drop files here or click to upload
                                                                    </div>
                                                                </form>
                                                                <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-12 d-flex justify-content-end py-2">
                                                        <button type="button" className="btn btn-danger me-2" onClick={resetForm}>
                                                            <span className="ti-xs ti ti-x me-1"></span>Reset
                                                        </button>

                                                        <button
                                                            type="submit" name="submitButton" className="btn btn-primary">
                                                            <span class="ti-xs ti ti-brand-telegram me-1"></span>Save
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    }}
                                </Formik>
                            </div>

                        </TabPanel> */}
                    </div>
                </Tabs>
            </div>


        </>
    )
}

export default LeaveApplication
