import '../../styles/profileStyle.css'
import bgbannerImg from '../../assets/images/pages/profile-banner.png'
import studentprofile from '../../assets/images/avatars/studentprofile.png'
import avatars13 from '../../assets/images/avatars/13.png'
import avatars10 from '../../assets/images/avatars/10.png'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { showAllItems } from '../../app/dashboardSlice';


const MyChildren = () => {

    const dispatch = useDispatch();
    const showAllDashboardItems = useSelector((state) => state.dashboard.showAllDashboardItems);
    console.log(showAllDashboardItems);

    return (
        <>
            <div className="profile-head">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-4">
                            <div className="user-profile-header-banner">
                                <img src={bgbannerImg} alt="Banner image" className="rounded-top" />
                            </div>
                            <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                                <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                                    <img src={studentprofile} alt="user image" className="d-block h-auto ms-0 ms-sm-4 rounded user-profile-img" />
                                </div>
                                <div className="flex-grow-1 mt-3 mt-sm-5">
                                    <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                                        <div className="user-profile-info">
                                            <h4 className=' m-0'>Danielle Solomon</h4>
                                            <p>My Child</p>
                                            <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-3">
                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="fa-solid fa-school fa-sm"></i>  Six (A)
                                                </li>

                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="ti ti-award ti-sm"></i>  2
                                                </li>

                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="ti ti-registered ti-sm"></i>  RSM-00009
                                                </li>

                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="fa-solid fa-cake-candles fa-sm" />  29.Dec.2008
                                                </li>


                                            </ul>
                                        </div>
                                        <Link
                                            onClick={() => {
                                                dispatch(showAllItems());
                                            }}
                                            to="dashboard" className="btn btn-primary waves-effect waves-light">
                                            <i class="fa-solid fa-gauge-high me-2"></i>Dashboard
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card mb-4">
                            <div className="user-profile-header-banner">
                                <img src={bgbannerImg} alt="Banner image" className="rounded-top" />
                            </div>
                            <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                                <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                                    <img src={avatars13} alt="user image" className="d-block h-auto ms-0 ms-sm-4 rounded user-profile-img" />
                                </div>
                                <div className="flex-grow-1 mt-3 mt-sm-5">
                                    <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                                        <div className="user-profile-info">
                                            <h4 className=' m-0'>Gajendra Brahmbhatt</h4>
                                            <p>My Child</p>
                                            <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-3">
                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="fa-solid fa-school fa-sm"></i>  Six (A)
                                                </li>

                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="ti ti-award ti-sm"></i>  2
                                                </li>

                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="ti ti-registered ti-sm"></i>  RSM-00009
                                                </li>

                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="fa-solid fa-cake-candles fa-sm" />  29.Dec.2008
                                                </li>


                                            </ul>
                                        </div>
                                        <Link
                                            onClick={() => {
                                                dispatch(showAllItems());
                                            }}
                                            to="dashboard" className="btn btn-primary waves-effect waves-light">
                                            <i class="fa-solid fa-gauge-high me-2"></i>Dashboard
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card mb-4">
                            <div className="user-profile-header-banner">
                                <img src={bgbannerImg} alt="Banner image" className="rounded-top" />
                            </div>
                            <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                                <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                                    <img src={avatars10} alt="user image" className="d-block h-auto ms-0 ms-sm-4 rounded user-profile-img" />
                                </div>
                                <div className="flex-grow-1 mt-3 mt-sm-5">
                                    <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                                        <div className="user-profile-info">
                                            <h4 className=' m-0'>Krishna Dey</h4>
                                            <p>My Child</p>
                                            <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-3">

                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="fa-solid fa-school fa-sm"></i>  Six (A)
                                                </li>

                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="ti ti-award ti-sm"></i>  2
                                                </li>

                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="ti ti-registered ti-sm"></i>  RSM-00009
                                                </li>

                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="fa-solid fa-cake-candles fa-sm" />  29.Dec.2008
                                                </li>

                                            </ul>
                                        </div>
                                        <Link
                                            onClick={() => {
                                                dispatch(showAllItems());
                                            }}
                                            to="dashboard" className="btn btn-primary waves-effect waves-light">
                                            <i class="fa-solid fa-gauge-high me-2"></i>Dashboard
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>

        </>
    )
}

export default MyChildren
